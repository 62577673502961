import React from "react"
import { useContext } from "react"
import { Col } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import Table from "react-bootstrap/Table"
import ListGroup from "react-bootstrap/ListGroup"
import { ContexteFormationFlat } from "../imprimable"
import { AcquisAppFlat_t } from "../api-syllabus-arbre"
import { useTranslation } from "react-i18next"

interface Props {
	acquisapp: Array<AcquisAppFlat_t>
}
/**
 * AcquisApprentissageFlat
 * 
 * Objet React de manipulation (affichage/édition) des acquis d'apprentissage
 * associés à un élément
 * 
 * @returns JSX.Element | null 
 */
export const AcquisApprentissageFlat = ( { acquisapp }: Props ): JSX.Element | null => {
	const { t } = useTranslation()
	const ctxt = useContext(ContexteFormationFlat)

	const widthCol = (val: number) => ({width: val + "%"})

	const lstRows = acquisapp?.map((aa,index) => {
		return <tr key={index}>
			<th scope="row" className="text-center pt-2">{index+1}</th>
			<td className="list-group p-1 b-0">
				<ListGroup.Item className="p-1">
					<LigneBilingueFlat texte={ aa.libAcquisApp } langue={ctxt.langue}/>
				</ListGroup.Item>
				{aa.mcc &&
					<ListGroup.Item className="p-1">
						<span className="text-muted">{t("acquisApprentissageFlat.expectedProof")}</span><br/>
						<LigneBilingueFlat texte={ aa.mcc } langue={ctxt.langue}/>
					</ListGroup.Item>
				}
			</td>
		</tr>
	})

	// reste à élaguer

	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>{t("acquisApprentissageFlat.title")}</h3>
		<Table hover size="sm" className="my-0" borderless>
			<colgroup>
				<col style={widthCol(5)}/>
				<col style={widthCol(95)}/>
			</colgroup>
			{/* <thead className="thead-light">
				<tr>
					<th scope="col" className="text-center">#</th>
					<th scope="col" className="text-left">Libellé</th>
				</tr>
			</thead> */}
			<tbody>{lstRows}</tbody>
		</Table>
	</Col>
}
