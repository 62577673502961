// src/i18n.ts
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Import des fichiers de traduction
import translationFR from "./locales/fr.json"
import translationEN from "./locales/en.json"

// Définition des ressources
const resources = {
	fr: {
		translation: translationFR
	},
	en: {
		translation: translationEN
	}
}

// Initialisation d’i18next
i18n
	.use(initReactI18next) // passe i18n à react-i18next
	.init({
		resources,
		lng: "fr", // langue par défaut
		fallbackLng: "en", // langue de secours si la clé n'est pas trouvée dans la langue courante
		keySeparator: ".", // les clés de traduction utilisent le point pour séparer les niveaux
		interpolation: {
			escapeValue: false // react s'occupe déjà de l'échappement
		}
	})

export default i18n
