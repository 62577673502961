import React from "react"
import { Toast, Button } from "react-bootstrap"
import { DescElpWrite_t } from "../syllabus/syllabus-types"
import { DescriptionEtp_tw } from "../syllabus/details-etp"

export interface ErrorDetails {
	message: string
	payload?: DescElpWrite_t | DescriptionEtp_tw
  }

interface ToastProps {
	erreur: ErrorDetails | null
	onDismiss: (x: ErrorDetails | null) => void
}

export const ErrorToast = ( {erreur, onDismiss}: ToastProps ):JSX.Element => {

	const stringPayload = (erreur) ? JSON.stringify(erreur.payload, null, 2) : ""

	const copyPayload = ():void => {
		if (erreur) {
			navigator.clipboard.writeText(JSON.stringify(erreur, null, 2))
		}
	}

	return (
		<Toast
			style={{ position: "absolute", top: 0, right: 0, zIndex: 9999 }}
			show={erreur !== null}
			onClose={() => onDismiss(null)}
			bg="warning"
		>
			<Toast.Header className="bg-warning text-danger">
				<h4 className="mr-auto">Erreur</h4>
			</Toast.Header>
			<Toast.Body className="alert-danger">
				{erreur?.message ?? "Erreur inconnue"}
				{erreur?.payload && (
					<div style={{ marginTop: "10px" }}>
						<strong>Données envoyées :</strong>
						<pre style={{ whiteSpace: "pre-wrap" }}>
							{stringPayload}
						</pre>
						<Button variant="secondary" size="sm" onClick={copyPayload}>
							Copier les données dans le presse papier
						</Button>
					</div>
				)}
			</Toast.Body>
		</Toast>
	)
}

