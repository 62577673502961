import React from "react"
import { Col, Table } from "react-bootstrap"
import { Charges_t } from "../../apogee/apogee-types"
import { useTranslation } from "react-i18next"

interface PropsCharges {
	charges: Charges_t
}

export const ChargesFlat = ({ charges }: PropsCharges): JSX.Element | null => {
	const { t } = useTranslation()

	// Association des clés de traduction aux champs de charges (hors "codAn")
	const mapHeadToChargeKeys: { [key in keyof Omit<Charges_t, "codAn">]: string } = {
		hCM: "chargesFlat.headers.cm",
		hCMTD: "chargesFlat.headers.cmtd",
		hTD: "chargesFlat.headers.td",
		hTP: "chargesFlat.headers.tp",
		hTERRAI: "chargesFlat.headers.terrain",
		hCNP: "chargesFlat.headers.cnp",
	}

	if (charges == null) return null

	// Fonction de création d'une ligne avec un préfixe (ici, "REH (h)" ou autre)
	const row = (prefix: string, ch: Charges_t | null | undefined) =>
		ch && (
			<tr>
				<th scope="row">{prefix}</th>
				{(Object.keys(mapHeadToChargeKeys) as (keyof Charges_t)[]).map((key, index) => (
					<th key={index} scope="col" className="text-center">
						{ch[key]}
					</th>
				))}
			</tr>
		)

	return (
		<Col xs={12} className="mx-0 mt-2 mb-1">
			<Table striped bordered hover size="sm" className="m-0">
				<thead>
					<tr>
						<th scope="col">{t("chargesFlat.studentCharge")}</th>
						{(Object.values(mapHeadToChargeKeys) as string[]).map((translationKey, index) => (
							<th key={index} scope="col" className="text-center">
								{t(translationKey)}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{row(t("chargesFlat.reh"), charges)}
					{/* row('Corrections', cpl) */}
				</tbody>
			</Table>
		</Col>
	)
}
