import React from "react"
import { useState } from "react"
import { Card, Form, Modal } from "react-bootstrap"
import { useForm, FormProvider } from "react-hook-form"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { Etat_t } from "./syllabus-types"

interface EtatFormulaireProps {
	etat?: Etat_t
	update?: (value: Etat_t) => void
	onCancel: () => void
}

const EtatFormulaireComponent = ({ etat, update, onCancel }: EtatFormulaireProps): JSX.Element => {
	const majConv = (v : boolean | null): string => (v === true) ? "true" : (v === false) ? "false" : "null"

	interface Etat_tf {
		public: boolean
		ajour: string
		annee: number
	}

	const methods = useForm<Etat_tf>({
		mode: "onChange",
		defaultValues: {
			public: etat?.public ?? true,
			ajour: majConv(etat?.ajour ?? null),
			annee: etat?.annee ?? 2020
		}
	})
	const { handleSubmit, formState: { dirtyFields } } = methods

	const onSubmit = (value: Etat_tf) => {
		const newEtat: Etat_t = {
			public: value.public,
			ajour: value.ajour === "true" ? true : value.ajour === "false" ? false : null,
			annee: value.annee
		}
		if ((!etat || etat.public !== newEtat.public || etat.ajour !== newEtat.ajour ) &&  (update)) {
			update(newEtat)
		}
		onCancel()
		// console.log(newEtat)
	}

	return (
		<FormProvider {...methods}>
			<h2 className="mt-2">Etat de rédaction de l&apos;élément</h2>
			<Form onSubmit={handleSubmit(onSubmit)}>
				{/* Champ Public */}
				<Form.Group className="my-3 ms-4" controlId="etatPublic">
					<Form.Label className="text-primary fw-bold">Caractère Public/Privé</Form.Label>
					<Form.Check type="checkbox"  id="etatPublicCheck">
						<Form.Check.Input {...methods.register("public")} />
						<Form.Check.Label className={dirtyFields.public ? "border border-danger px-1 py-0" : "border border-white px-1 py-0"}>
							Public
						</Form.Check.Label>
					</Form.Check>
					<Form.Text className="text-muted">
						indique si cet élément doit être affiché dans les versions publiques du syllabus.
					</Form.Text>
				</Form.Group>
				{/* Champ Avancement (ajour) */}
				<Form.Group className="my-3 ms-4" controlId="etatAjour">
					<Form.Label className="text-primary fw-bold">Avancement de rédaction</Form.Label>
					<Form.Select
						{...methods.register("ajour")}
						className={dirtyFields.ajour ? "border border-danger" : ""}
						onBlur={(e) => e.target.blur()}
					>
						<option value="null">Elément non vérifié encore</option>
						<option value="false">Elément en cours de mise à jour</option>
						<option value="true">Elément à jour pour l&apos;année indiquée</option>
					</Form.Select>
					<Form.Text className="text-muted">
						Sélectionnez l&apos;état de mise à jour de l&apos;élément.
					</Form.Text>
				</Form.Group>
				<OkCancel cancel={onCancel} />
			</Form>
		</FormProvider>
	)
}

// On mémorise le composant pour qu'il ne soit recréé que si ses props changent
const MemoizedEtatFormulaire = React.memo(EtatFormulaireComponent)

interface PropsEtat {
	header?: JSX.Element
	etat?: Etat_t
	update?: (value: Etat_t) => void
}

export const Etat = ({header, etat, update} : PropsEtat): JSX.Element => {
	const [edit, setEdit] = useState(false)
	const onclickEdit = () => setEdit(true)
	const onclickCancel = () => setEdit(false)

	return <Card className="mx-1 mb-1">
		<Card.Body className='py-2'>
			<Card.Title className="text-primary mb-2">
				<EditButton onClick={onclickEdit}/>
				Etat de l&apos;élément
			</Card.Title>
			<Card.Text className="mb-1">
				<span className="text-primary">Privé/Public&thinsp;: </span>
				{(etat?.public ?? true)
					? "Elément affiché "
					: "Elément non affiché "} dans les versions publiques du syllabus
			</Card.Text>
			<Card.Text className="mb-1">
				<span className="text-primary">Avancement de rédaction&thinsp;: </span>
				{(etat?.ajour === true)
					? "Description à jour "
					: (etat?.ajour === false)
						? "Description en cours de mise à jour "
						: "Description non vérifiée "} pour l&apos;année référencée
			</Card.Text>
		</Card.Body>
		<Modal show={edit} keyboard={false} backdrop="static"  onHide={onclickCancel}>
			{ header }
			<Modal.Body>{ edit && <MemoizedEtatFormulaire etat={etat} update={update} onCancel={onclickCancel} /> }</Modal.Body>
		</Modal>
	</Card>
}
