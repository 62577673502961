import React, { useCallback } from "react"
import { useState, useEffect, useContext } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { set } from "lodash"
import { ApogeeElp_t } from "../apogee/apogee-types"
import {
	DescElpRead_t,
	DescElpWrite_t,
	Factuel_t,
	Factuel_tw,
	CplDuree_t,
	Libelle_t,
	LangueEnseignement_t,
	Etat_t
} from "./syllabus-types"
import { ErrorDetails, ErrorToast } from "../utilities/error-toast"
import { Titres } from "./titres"
import { SuiviRedaction } from "./suivi-redaction"
import { ContexteDetails } from "./details"
import { useSanctum } from "../sanctum/sanctum"
import { Langue } from "./langue"
import { Travail } from "./travail"
import { Etat } from "./etat"

/**
 * Factuel
 *
 * Objet React de manipulation (affichage/édition) des données factuelles d'un élément
 *
 * @returns JSX.Element | null
 */
export const Factuel = (): JSX.Element | null => {
	const {
		apiAccess,
		authState: { user }
	} = useSanctum()

	const [apogee, setApogee] = useState<ApogeeElp_t | null>(null)
	const [factuel, setFactuel] = useState<Factuel_t | null>(null)
	const [erreur, setErreur] = useState<ErrorDetails | null>(null)
	const ctxt = useContext(ContexteDetails)

	// useEffect(() => console.log({ apogee, factuel }))

	/**
	 * fetchDescription
	 *
	 * fonction asynchrone d'écriture/lecture des données factuelles
	 *
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 *
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = useCallback(
		async (valeur: DescElpWrite_t): Promise<void> => {
			try {
				const reponse = await apiAccess.post<DescElpRead_t>(
					ctxt.url,
					valeur
				)
				if (reponse.data.apogee !== undefined)
					setApogee(reponse.data.apogee)
				if (reponse.data.factuel !== undefined)
					setFactuel(reponse.data.factuel)
				// si l'etat de l'Elp a été modifié, il faut le mettre à jour
				// console.log(valeur)
				if (valeur?.factuel?.etat && ctxt.refreshParent) {
					ctxt.refreshParent()
					// console.log("refresh")
				}
			} catch (err: unknown) {
				if (err instanceof Error) {
					setErreur({ message: err.message, payload: valeur } as ErrorDetails)
				}
			}
		},
		[apiAccess, ctxt]
	)

	useEffect(() => {
		fetchDescription({ factuel: null, apogee: null })
	}, [fetchDescription])

	if (apogee == null) return null

	const baseFactuel: Factuel_tw = {
		//		codElp: apogee.cod_elp,
		//		validite: `[${ctxt.annee},)`,
		commentaire: null,
		libelle: null,
		cplDuree: null,
		langEns: null,
		etat: {public: true, ajour: null, annee: ctxt.annee}
	}

	/**
	 * updateFn
	 *
	 * Générateur de callback de mise à jour
	 *
	 * @param key
	 * @returns
	 */
	function updateFn<Type>(key: string) {
		return (value: Type) => {
			const factuel: Factuel_tw = set(
				Object.assign({}, baseFactuel),
				key,
				value
			)
			fetchDescription({ factuel })
		}
	}

	function updateAnglais(valeur: LangueEnseignement_t) {
		const factuel: Factuel_tw = set(
			Object.assign({}, baseFactuel),
			"langEns",
			valeur
		)
		fetchDescription({ factuel })
	}

	const coeur = (): JSX.Element => (
		<Row>
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Col xs="12">
				<SuiviRedaction
					contenu={factuel?.commentaire}
					header={ctxt.header}
					update={updateFn<string>("commentaire")}
				/>
			</Col>
			<Col xs="12">
				<Etat
					etat={factuel?.etat}
					header={ctxt.header}
					update={updateFn<Etat_t>("etat")}
				/>
			</Col>
			<Col xs="12">
				<Titres
					libelle={factuel?.libelle}
					lic={apogee.lic_elp}
					header={ctxt.header}
					update={updateFn<Libelle_t>("libelle")}
				/>
			</Col>
			{apogee?.chargeable && (
				<Col xs="12">
					<Travail
						header={ctxt.header}
						nature={apogee.lic_nel}
						cplDuree={factuel?.cplDuree}
						update={updateFn<CplDuree_t>("cplDuree")} // à modifier
					/>
				</Col>
			)}
			{apogee?.chargeable && (
				<Col xs="12">
					<Langue
						header={ctxt.header}
						langEns={factuel?.langEns ?? "fr"}
						update={updateAnglais} // à modifier
					/>
				</Col>
			)}
		</Row>
	)

	return user ? (
		<Card className="m-1">
			<Card.Header>
				<span className="text-primary h4">
					Données factuelles et suivi de rédaction
				</span>
				{factuel && factuel.updated_by && (
					<span className="text-info small">
						&nbsp;(édité{" "}
						{(() => {
							const date = new Date(factuel.updated_at + "Z")
							return (
								`à ${date.toLocaleTimeString("fr-FR")} le ` +
								date.toLocaleDateString("fr-FR") +
								" par " +
								factuel.updated_by
							)
						})()}
						)
					</span>
				)}
			</Card.Header>
			<Card.Body className="m-1 p-0 mt-0">{coeur()}</Card.Body>
		</Card>
	) : (
		coeur()
	)
}
