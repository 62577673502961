import React, { useCallback } from "react"
import { useState, useEffect, createContext } from "react"
import { Card, Container, Tab, Tabs } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useSanctum } from "../sanctum/sanctum"
import { Elp } from "./elp"
import { LigneBilingue } from "../utilities/bilingue"
import { Parcours_t } from "../ecole/api-ecole"
import { Formation_t } from "./api-parcours"

/**
 * FormationContext_t
 *
 * Contexte de formation: description du parcours
 */
export interface FormationContext_t {
	formation?: Parcours_t
	langue?: string
	setLangue?: (langue: string) => void
}

export const ContexteFormation = createContext<FormationContext_t>({})

export const Parcours = (): JSX.Element | null => {
	const { apiAccess } = useSanctum()
	const { code } = useParams() // paramètre de la route
	const [formation, setFormation] = useState<Formation_t | null>(null)
	const [langue, setLangue] = useState<string>("fr")

	const fetch = useCallback(async (): Promise<void> => {
		try {
			const reponse = await apiAccess.get<Formation_t>("/api/parcours/" + code)
			setFormation(reponse.data)
		} catch (error) {
			if (process.env.NODE_ENV === "development")
				console.error({ from: "Apogee.Formation.fetch", error })
		}
	}, [apiAccess, code])

	// actif seulement au montage
	useEffect(() => {
		fetch()
	}, [fetch])

	// Callback à transmettre au composant Details pour rafraîchir les données
	const refreshEtp = () => {
		fetch()
	}

	const frm = formation?.formation

	const header = !frm ? (
		<header>
			<h1 className="text-center text-muted mb-4">Formation {code}</h1>
		</header>
	) : (
		<header>
			<h1 className="text-center text-muted mb-4">
				Formation {frm.codParcours}
			</h1>
			<h3 className="text-center text-muted mb-4">
				<LigneBilingue
					texte={ frm.licParcours }
				/>
			</h3>
			{frm.descParcours?.fr && (
				<div className="ms-2 me-auto">
					<span className="text-primary">Description&thinsp;: </span>
					<LigneBilingue texte={frm.descParcours} />
				</div>
			)}
		</header>
	)

	const ans = formation?.etapes
	const annees = ans && Object.keys(ans).sort((a, b) => b.localeCompare(a))
	const ctxtObj = { formation: frm, langue, setLangue }
	const tabs = frm && ans && annees && (
		<ContexteFormation.Provider value={ctxtObj}>
			<Container fluid>
				<Tabs defaultActiveKey={0} id="annee-universitaire" variant="pills" mountOnEnter>
					{annees.map((an, ixa) => {
						const annee = Number(an)
						return (
							<Tab key={ixa} eventKey={ixa} title={an + " — " + (annee + 1)}>
								<Card border="primary" className="border-5 p-1">
									<Tabs
										defaultActiveKey={0}
										id="etapes"
										variant="pills"
										mountOnEnter
									>
										{Object.values(ans[an]).map((etp, ixe) => {
											return (
												<Tab
													key={ixe}
													eventKey={ixe}
													title={etp.codVet + ": " + etp.licVet}
												>
													<Elp
														annee={annee}
														nature={"étape"}
														code={etp.codVet}
														refreshParent={refreshEtp}
													/>
												</Tab>
											)
										})}
									</Tabs>
								</Card>
							</Tab>
						)
					})}
				</Tabs>
			</Container>
		</ContexteFormation.Provider>
	)

	return (
		<>
			{header}
			{tabs}
		</>
	)
}
