import React, { useCallback } from "react"
import {
	useState,
	useEffect,
	Reducer,
	useReducer,
	FocusEvent,
	useContext
} from "react"
import {
	Row,
	Col,
	Button,
	Card,
	Form,
	InputGroup,
	Modal,
	OverlayTrigger,
	Tooltip,
	Badge
} from "react-bootstrap"
import AsyncCreatableSelect from "react-select/async-creatable"
import { RotateCcw } from "react-feather"
import { nanoid } from "nanoid"
import { Placement } from "react-bootstrap/types"
import {
	DescElpRead_t,
	DescElpWrite_t,
	MotClefH_t,
	MotClefH_tp,
	MotClef_t
} from "./syllabus-types"
import { Bilingue_t, LigneBilingue } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { ErrorDetails, ErrorToast } from "../utilities/error-toast"
import { ContexteDetails } from "./details"
import { useSanctum } from "../sanctum/sanctum"

/**
 * MotsClefs
 *
 * Objet React de manipulation (affichage/édition) des mots clefs associés à un élément
 *
 * @returns JSX.Element | null
 */
export const MotsClefs = (): JSX.Element | null => {
	const {
		apiAccess,
		authState: { user }
	} = useSanctum()

	const [motsclefs, setMotsClefs] = useState<MotClefH_t[] | null>(null)
	const [erreur, setErreur] = useState<ErrorDetails | null>(null)
	const [edit, setEdit] = useState(false)
	const ctxt = useContext(ContexteDetails)

	const onclickEdit = () => setEdit(true)

	const compareFR = Intl.Collator("fr", { sensitivity: "base" })
	// const compareFRstrict = Intl.Collator("fr", {sensitivity: "variant"})
	// const compareEN = Intl.Collator("en", {sensitivity: "base"})
	const compareENstrict = Intl.Collator("en", { sensitivity: "variant" })

	/**
	 * fetchDescription
	 *
	 * fonction asynchrone d'écriture/lecture d'une liste de mots clefs
	 *
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 *
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = useCallback(
		async (valeur: DescElpWrite_t): Promise<void> => {
			try {
				const reponse = await apiAccess.post<DescElpRead_t>(
					ctxt.url,
					valeur
				)
				const mc = reponse.data.motsclefs
				if (mc !== undefined) setMotsClefs(mc)
			} catch (err: unknown) {
				if (err instanceof Error) {
					setErreur({ message: err.message, payload: valeur } as ErrorDetails)
				}
			}
		},
		[apiAccess, ctxt.url]
	)

	useEffect(() => {
		fetchDescription({ motsclefs: null })
	}, [fetchDescription])

	// useEffect(() => console.log({motsclefs}))

	const aLePar = (aa: MotClefH_t) => {
		const date = new Date(aa.updated_at.pivot + "Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa: MotClefH_tp) => {
		// à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj + "Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const TemoinModif = (): JSX.Element => (
		<Badge
			bg="danger"
			pill
			className="position-absolute top-0 start-100 translate-middle border border-light"
		>
			&nbsp;
		</Badge>
	)

	const Formulaire = () => {
		type KW_t = {
			// représentation des mots clefs dans le formulaire
			id: string
			del: boolean
			neuf: boolean
			diffOrg: boolean
			propre: boolean
			org: MotClefH_tp | null
			lib: Required<Bilingue_t>
			val: string
		}

		type PartialKwt = {
			[P in keyof KW_t]+?: KW_t[P] extends Bilingue_t
				? Partial<Bilingue_t>
				: KW_t[P]
		}

		type action_t = {
			// actions du reducer
			type: string
			value?: PartialKwt
		}

		const kwReducer = (state: KW_t[], action: action_t): KW_t[] => {
			// console.log( { action })
			const ident = action.value?.id
			function kw2kwf(aa: MotClefH_tp): KW_t {
				return {
					id: nanoid(),
					del: false,
					neuf: false,
					diffOrg: false,
					propre: aa.codElp.includes(aa.troncCodElp),
					org: aa,
					lib: { fr: aa.texteMC.fr, en: aa.texteMC.en ?? "" },
					val: aa.validite ?? "période inconnue"
				}
			}
			switch (action.type) {
				case "reset":
					return []
				case "init": // réinitialisation avec les mots clefs
					// Les mots clefs sont classés avec les 'mots clefs seulement propagés' en premier
					return (
						motsclefs
							?.map<KW_t>(kw2kwf)
							.sort((a: KW_t, b: KW_t) =>
								a.org?.validite
									? b.org?.validite
										? compareFR.compare(a.lib.fr, b.lib.fr)
										: 1
									: b.org?.validite
										? -1
										: compareFR.compare(a.lib.fr, b.lib.fr)
							) ?? []
					)
				case "add": {
					// si org est set, il a la priorité
					const libfr =
						action.value?.org?.texteMC ?? action.value?.lib?.fr
					if (libfr === undefined) return state // sortie rapide: on ne peut pas ajouter rien
					if (!state.find((aaf) => aaf.lib.fr === libfr)) {
						// n'a pas été trouvé. On insère en fin de liste.
						const org = action.value?.org
						// prettier-ignore
						const nouv: KW_t =
							org == null
								? {
									id: nanoid(),
									del: false,
									neuf: true,
									diffOrg: true,
									propre: true,
									org: null,
									lib: {
										fr: action.value?.lib?.fr ?? "",
										en: action.value?.lib?.en ?? ""
									},
									val: "l'année courante et les suivantes (a priori)"
								} : { ...kw2kwf(org), neuf: true }
						return [...state, nouv]
						// ce qui suit permettait d'insérer dans une liste ordonnée...
						// const point = state.findIndex( e => e.lib.fr > nouv.lib.fr )
						// switch (point) {
						// case -1: return [...state, nouv]
						// case 0: return [nouv, ...state]
						// default: return [...state.slice(0,point), nouv, ...state.slice(point)]
						// }
					}
					// si l'objet a été trouvé, le add est interprété comme un undel
					return state.map((aaf) => {
						if (aaf.lib.fr === libfr)
							return { ...aaf, propre: true, del: false }
						else return aaf
					})
				}
				case "bascule": {
					if (!ident)
						return state // id non indiqué...
					else
						return state.map((aaf) => {
							if (aaf.id === ident) {
								const del =
									aaf.org?.brancheCodElp == null && !aaf.del
								const propre =
									aaf.org?.brancheCodElp == null ||
									!aaf.propre
								return { ...aaf, propre: propre, del: del }
							} else return aaf
						})
				}
				case "maj": {
					// l'aaf est désigné par son id
					if (!ident) return state // id non indiqué...
					return state.map((aaf) => {
						if (aaf.id !== ident) return aaf
						else {
							const libfr = action.value?.lib?.fr ?? aaf.lib.fr
							const liben = action.value?.lib?.en ?? aaf.lib.en
							return {
								// Les champs non mis à jour sont nuls ou undefined (dans action.value)
								id: aaf.id,
								del: aaf.del,
								neuf: aaf.neuf,
								diffOrg:
									aaf.org === null ||
									libfr !== aaf.org.texteMC.fr ||
									liben !== (aaf.org?.texteMC.en ?? ""),
								propre: action.value?.propre ?? aaf.propre,
								org: aaf.org,
								lib: { fr: libfr, en: liben },
								val: action.value?.val ?? aaf.val
							}
						}
					})
				}
				default:
					return state
			}
		}

		const [kw, setKW] = useReducer<Reducer<KW_t[], action_t>>(kwReducer, [])
		const actionReset: action_t = { type: "reset" }

		// useEffect(() => console.log({kw}))

		if (kw.length === 0 && motsclefs !== null && motsclefs.length !== 0)
			setKW({ type: "init" })

		const onclickCancel = () => {
			// console.log({ kw })
			setEdit(false)
			setKW(actionReset)
		}
		const onclickValid = () => {
			const aa: MotClef_t[] = kw
				.filter((aaf) => !aaf.del && aaf.propre)
				.map<MotClef_t>((aaf) => ({
					// tout est transféré tel qu'affiché, sauf la validité qui sera recalculée en backend
					texteMC: {
						fr: aaf.lib.fr,
						en: aaf.neuf || aaf.diffOrg ? aaf.lib.en : undefined
					}
				}))
			// console.log({ kw, aa })
			fetchDescription({ motsclefs: aa })
			setEdit(false)
			setKW(actionReset)
		}

		// prettier-ignore
		const suggestionsMC = async (inputValue: string) => {
			return inputValue.length > 2
				? apiAccess.post<{
						fr: string
						en?: string
						updated_at: Date
						updated_by?: string
					}[]>("api/selecteur/mot_clef", { motif: inputValue })
					.then((res) =>
						res.data.map((x) => {
							return {
								value: x,
								label: x.fr
							}
						})
					)
				: Promise.resolve([])
		}

		const Prologue = () => (
			<div className="lh-sm">
				<p>
					Indiquer ci-après quelques mots clefs spécifiques à
					l&apos;élement. Le sélecteur permet d&apos;ajouter des mots
					clefs en français et, à partir de trois lettres, il propose
					des mots clefs déjà existant dans le syllabus. La colonne de
					droite permet de saisir une traduction anglaise. (Attention
					de n&apos;indiquer qu&apos;un seul mot clef par ligne&nbsp;:
					il est possible de rajouter autant de lignes que
					nécessaire).
				</p>
				<p>
					L&apos;affichage reprend les mots clefs déclarés sur les
					éléments constitutifs de l&apos;élément courant. Seuls les
					mots clefs propres à l&apos;élément courant sont
					modifiables. Le bouton de couleur placé sous le mot clef
					permet de supprimer ou réactiver un mot clef déclaré. Le
					bouton <RotateCcw size={16} /> placé à droite permet
					d&apos;annuler les modifications faites à la traduction
					anglaise. Enfin, les modifications ne deviennent définitives
					qu&apos;après validation.
				</p>
			</div>
		)

		const deco = (aaf: KW_t) =>
			aaf.del
				? { textDecoration: "line-through red wavy" }
				: aaf.propre
					? { textDecoration: "none" }
					: { textDecoration: "line-through yellow" }

		interface PropsFormAA {
			aaf: KW_t
		}

		const LibAcquisApp = ({ aaf }: PropsFormAA) => (
			<Col>
				<OverlayTrigger
					placement={"top"}
					overlay={
						<Tooltip id={`tooltip-${aaf.id}`}>
							Défini pour {aaf.val}
						</Tooltip>
					}
				>
					<Form.Control
						value={aaf.lib.fr}
						as="textarea"
						rows={1}
						readOnly
						style={deco(aaf)}
					/>
				</OverlayTrigger>
			</Col>
		)

		interface PropsInputFormAA extends PropsFormAA {
			placeOvl: Placement
			nom: string
			valeur: string
			changeFn: (a: string) => PartialKwt
			reset: PartialKwt
			readonly: boolean
		}

		const InputAcquisApp = ({
			aaf,
			placeOvl,
			nom,
			changeFn,
			valeur,
			reset,
			readonly
		}: PropsInputFormAA) => {
			const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
				if (!readonly)
					setKW({
						type: "maj",
						value: { ...changeFn(event.target.value), id: aaf.id }
					})
			}
			const resetVal = () => {
				if (!readonly)
					setKW({ type: "maj", value: { ...reset, id: aaf.id } })
			}
			return (
				<Col>
					<OverlayTrigger
						placement={placeOvl}
						overlay={
							<Tooltip id={`${nom}-${aaf.id}`}>{nom}</Tooltip>
						}
					>
						<InputGroup>
							<Form.Control
								as="textarea"
								defaultValue={valeur}
								onBlur={onBlur}
								placeholder={nom}
								readOnly={readonly}
								rows={1}
								style={deco(aaf)}
							/>
							<RotateCcw
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center"
								}}
								size={16}
								className="btnSyllabus ml-2"
								onClick={resetVal}
							/>
						</InputGroup>
					</OverlayTrigger>
				</Col>
			)
		}

		interface MarqueSiModifProps {
			obj: KW_t
		}

		const MarqueSiModif = ({
			obj
		}: MarqueSiModifProps): JSX.Element | null => {
			if (obj.neuf) {
				if (obj.del) return null
			} else if (
				!obj.del &&
				((obj.org?.validite ?? null) !== null) === obj.propre &&
				compareENstrict.compare(
					obj.org?.texteMC.en ?? "",
					obj.lib.en
				) === 0
			)
				return null
			return <TemoinModif />
		}

		// prettier-ignore
		return ( <>
			<h1 className="mt-2">Mots Clef</h1>
			<Prologue />
			<OkCancel cancel={onclickCancel} valid={onclickValid} />
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Form>
				{kw && kw.map((mc) => (
					<Row
						key={`kw${mc.id}`}
						className="my-2 p-1 border border-primary rounded"
					>
						<Col>
							<Row>
								<LibAcquisApp aaf={mc} />
								<InputAcquisApp
									aaf={mc}
									nom="keyword"
									placeOvl="top"
									changeFn={(v: string) => ({
										lib: { en: v }
									})}
									valeur={mc.lib.en ?? ""}
									reset={{
										lib: { en: mc.org?.texteMC.en ?? "" }
									}}
									readonly={mc.del || !mc.propre}
								/>
							</Row>
							{mc.org?.updated_by?.obj && (
								<p className="text-info small my-0">
									Édité {aLeParObj(mc.org)}
								</p>
							)}
							<Row className="mx-0 mt-1">
								<Col>
									<Button
										className="px-2 py-0 position-relative"
										type="button"
										variant={
											mc.propre && !mc.del
												? "success"
												: mc.org?.brancheCodElp
													? "warning"
													: "secondary"
										}
										onClick={() => setKW({
											type: "bascule",
											value: { id: mc.id }
										}) }
									>
										{mc.org?.brancheCodElp
											? (mc.propre
												? `Propre à l'élément ${ctxt.code} et propagé de `
												: "Seulement propagé de "
											) + mc.org.brancheCodElp.join( ", " )
											: mc.del
												? `Propre à l'élément ${ctxt.code} mais effacé`
												: `Propre à l'élément ${ctxt.code}`}
										<MarqueSiModif obj={mc} />
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</Form>
			<AsyncCreatableSelect
				cacheOptions
				loadOptions={suggestionsMC}
				onCreateOption={(inputValue: string) =>
					setKW({
						type: "add",
						value: { lib: { fr: inputValue } }
					})
				}
				onChange={(kw) => {
					if (kw && "value" in kw)
						setKW({
							type: "add",
							value: {
								org: {
									texteMC: {
										fr: kw.value.fr,
										en: kw.value.en
									},
									troncCodElp: ctxt.code,
									codElp: [ctxt.code],
									caractere: "O",
									brancheCodElp: null,
									updated_at: {
										obj: kw.value.updated_at,
										pivot: kw.value.updated_at
									},
									updated_by: {
										obj: kw.value.updated_by ?? null,
										pivot: null
									}
								}
							}
						})
				}}
				className="mb-2 mt-2"
			/>
			<OkCancel cancel={onclickCancel} valid={onclickValid} />
		</> )
	}

	const lstItems = motsclefs?.map((elt) => {
		const famille = elt.brancheCodElp ?? elt.codElp
		return (
			<li
				key={elt.idMotClef}
				className={elt.validite === undefined ? "text-muted" : ""}
			>
				<LigneBilingue texte={elt.texteMC} />
				<span className="text-info small">
					&nbsp;{elt.updated_by.obj && `édité ${aLeParObj(elt)} ; `}
					{elt.validite !== null
						? elt.brancheCodElp === null
							? elt.updated_by.pivot
								? "affecté " + aLePar(elt)
								: ""
							: "propre à l'élément " +
								elt.troncCodElp +
								(elt.updated_by.pivot
									? ` (affecté ${aLePar(elt)})`
									: "") +
								" et propagé de "
						: elt.brancheCodElp === null
							? ""
							: "propagé de "}
					{elt.brancheCodElp && famille.join(", ")}
				</span>
			</li>
		)
	})

	if ((lstItems?.length ?? 0) === 0 && (user ?? null) === null) return null

	return (
		<Row>
			<Col xs="12">
				<ErrorToast erreur={erreur} onDismiss={setErreur} />
				<Card className="mx-1 mb-1">
					<Card.Header className="text-primary h5">
						<EditButton onClick={onclickEdit} />
						Mots clés
					</Card.Header>
					<Card.Body className="py-2">
						<ul className="mb-0">{lstItems}</ul>
					</Card.Body>
					<Modal
						dialogClassName="modal-90w"
						show={edit}
						keyboard={false}
						backdrop="static"
					>
						{ctxt.header}
						<Modal.Body>{edit && <Formulaire />}</Modal.Body>
					</Modal>
				</Card>
			</Col>
		</Row>
	)
}
