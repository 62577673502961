import React from "react"
import { Link } from "react-router-dom"
import { LigneBilingue } from "../utilities/bilingue"
import { Parcours_t } from "./api-ecole"

interface Props {
	prc: Parcours_t
}

export const Specialite = ({ prc }: Props): JSX.Element => (
	<div className="mx-1 my-2 p-2 border border-secondary">
		<h5>
			<Link to={"/formation/" + prc.codParcours }>
				{prc.codParcours} :
			</Link><br />
			<small>
				<LigneBilingue texte={ prc.licParcours } />
			</small>
		</h5>
		{ prc.descParcours?.fr &&
			<div className="ms-2 me-auto">
				<span className="text-primary">Description&thinsp;: </span>
				<LigneBilingue texte={ prc.descParcours } />
			</div>
		}
	</div>
)