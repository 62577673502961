import React from "react"
import { Col, Row } from "react-bootstrap"
import { TexteBilingueFlat } from "./bilingue-flat"
import { ChargesFlat } from "./syllabus/charges-flat"
import { EtapeFlat_t } from "./api-syllabus-arbre"
import { useTranslation } from "react-i18next"


/**
 * Props
 * 
 * Paramètres de la fonction DetailsEtp
 */
interface Props {
	etape: EtapeFlat_t
}

/**
 * DetailsEtpFlat
 * 
 * Objet React de manipulation (affichage/édition) des détails d'une étape
 * 
 * La modification est gérée directement par les éléments SuiviRedaction, Titres, TexteBilingue
 * 
 * @param annee : nombre
 * @param code : chaine ~ '[-A-Zx0-9]+'
 * @param ouvert : booléen contrôlant l'affichage ou non du contenu
 * @returns JSX.Element | null
 */
export const DetailsEtpFlat = ({ etape }: Props): JSX.Element | null => {
	const { t } = useTranslation()

	if (etape == null) return null

	return <Col className="px-0">
		{etape.charges && <ChargesFlat charges={etape.charges} />}
		<Row>
			{ etape.notice?.contexte && <TexteBilingueFlat
				html titre={t("detailsEtpFlat.contextPresentationTitle")}
				texte={ etape.notice.contexte }
			/>}
		</Row>
	</Col>
}
