import React from "react"
import { useContext } from "react"
import { Card, Row } from "react-bootstrap"
import { DetailsEtpFlat } from "./details-etp-flat"
import { DetailsFlat } from "./details-flat"
import { ListesAvecTabsFlat, ListesSansTabsFlat } from "./listes-flat"
import { ContexteFormationFlat } from "./imprimable"
import { Bilingue_t } from "../utilities/bilingue"
import { ElementFlat_t, EtapeFlat_t } from "./api-syllabus-arbre"
import { useTranslation } from "react-i18next"

interface Props {
	annee: number
	element: ElementFlat_t | EtapeFlat_t
}

export const ElpFlat = ({ element, annee }: Props): JSX.Element | null => {
	const { t } = useTranslation()
	const ctxt = useContext(ContexteFormationFlat)

	let border = null
	switch (element.nature) {
		case "période":
			border = "primary"
			break
		case "module":
			border = "danger"
			break
		case "matière":
			border = "success"
			break
		default:
			border = "dark"
	}

	const listlength = element.listes?.length ?? 0

	// prettier-ignore
	const typeListes: string =
		element.listes?.some((l) => l.typLse === "X") ? "X"
			: element.listes?.some((l) => l.typLse === "F") ? "F" : "O"

	const lib =
		element.nature === "étape"
			? element.notice?.libelle
			: element.details?.factuel?.libelle
	const bilingue = (texte: Bilingue_t) =>
		ctxt.langue === "en" && texte?.en ? texte.en : texte?.fr
	const etiquette = (
		<h2 className="m-0">
			{(lib?.libelleLong
				? bilingue(lib.libelleLong)
				: lib?.libelleCourt
					? bilingue(lib.libelleCourt)
					: element.lic) + " "}
			({t(`elpFlat.nature.${element.nature}`)} {element.code})
			{element.nature === "matière"
				? t("elpFlat.coefficient") + element.nbCrd
				: " " + element.nbCrd + t("elpFlat.credits")}
		</h2>
	)

	const details = (
		<Row className="g-0">
			{element.nature === "étape" ? (
				<DetailsEtpFlat etape={element} />
			) : (
				<DetailsFlat element={element} />
			)}
		</Row>
	)

	return (
		<Card border={border} className="border-5 mt-1">
			<Card.Header className="container-fluid">
				{etiquette}
				{/*listlength > 0 && details*/}
			</Card.Header>

			<Card.Body className="pt-0">
				{details}
				{element.listes &&
					(listlength === 1 && typeListes === "O" ? (
						<ListesSansTabsFlat
							listes={element.listes}
							annee={annee}
						/>
					) : (
						<ListesAvecTabsFlat
							listes={element.listes}
							annee={annee}
						/>
					))}
			</Card.Body>
		</Card>
	)
}
