import React from "react"
import { useContext } from "react"
import Table from "react-bootstrap/Table"
import { Col, ListGroup } from "react-bootstrap"

import { CompetenceFlat_t } from "../api-syllabus-arbre"
import { useTranslation } from "react-i18next"
import { ContexteFormationFlat } from "../imprimable"
import { LigneBilingueFlat } from "../bilingue-flat"

/*
export interface CompetenceFlat_t extends SylBiParapheFlat_t {
	libCompetence: Bilingue_t
	ucModEvalComp: string // "C" | "E"
	ucNiveauComp: string // "1" | "2" | "3" | "4"
	ucBlocs: Array<string>
	validite: string
	updated_at: {
		obj: Date
		pivot: Date
	}
	updated_by: {
		obj?: string
		pivot?: string
	}
}
*/

interface Props {
	competences: Array<CompetenceFlat_t>
}

/**
 * CompetencesFlat
 *
 * Objet React de manipulation (affichage/édition) des compétences du RNCP
 * associées à un élément
 *
 * @returns JSX.Element | null
 */
export const CompetencesFlat = ( { competences }: Props): JSX.Element | null => {
	const { t } = useTranslation()
	const ctxt = useContext(ContexteFormationFlat)

	const lstRows = competences?.map((comp,index) => {
		return <tr key={index}>
			<th scope="row" className="text-center pt-2">{index+1}</th>
			<td className="list-group p-1 b-0">
				<ListGroup.Item className="p-1">
					<LigneBilingueFlat texte={ comp.libCompetence } langue={ctxt.langue}/>
				</ListGroup.Item>
				{comp.ucNiveauComp && ctxt.niveauxCompetence &&
					<ListGroup.Item className="p-1">
						<span className="text-muted">{t("competencesFlat.niveau")}</span>
						<LigneBilingueFlat texte={ ctxt.niveauxCompetence[comp.ucNiveauComp].descNiveauComp } langue={ctxt.langue}/>
					</ListGroup.Item>
				}
				{comp.ucModEvalComp && ctxt.modalitesEvalCompetence &&
					<ListGroup.Item className="p-1">
						<span className="text-muted">{t("competencesFlat.modeEvaluation")}</span>
						<LigneBilingueFlat texte={ ctxt.modalitesEvalCompetence[comp.ucModEvalComp].descModEvalComp } langue={ctxt.langue}/>
					</ListGroup.Item>
				}
				{comp.ucBlocs && ctxt.blocsCompetence &&
					<ListGroup.Item className="p-1">
						<span className="text-muted">{t("competencesFlat.blocsCompetenceAssocies")}</span><br/>
						<ul>
							{comp.ucBlocs.map((identifiant) => (
								<li key={identifiant}>
									{identifiant} : <LigneBilingueFlat
										texte={ctxt.blocsCompetence?.[identifiant]?.appellation ?? { fr: "", en: "" }}
										langue={ctxt.langue}
									/><br/>
								</li>
							))}
						</ul>
					</ListGroup.Item>
				}
			</td>
		</tr>
	})
	
	const widthCol = (val: number) => ({width: val + "%"})

	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>{t("competencesFlat.title")}</h3>
		<Table hover size="sm" className="my-0" borderless>
			<colgroup>
				<col style={widthCol(5)}/>
				<col style={widthCol(95)}/>
			</colgroup>
			<tbody>{lstRows}</tbody>
		</Table>
	</Col>

}
