import React from "react"
import { Badge, Card } from "react-bootstrap"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Nature_t } from "./api-structure"
import { ElementBref_t } from "./api-structure"
import { Liste_t } from "./api-structure"
import { Elp } from "./elp"

interface PropsListe {
	annee: number
	listes: Liste_t[]
	antecedents: { code: string; nature: Nature_t }[] // Propagation des antécédents
	refreshParent: () => void
}

interface PropsHeader {
	lst: Liste_t
}

const ListHeader = ({ lst }: PropsHeader) => {
	switch (lst.typLse) {
		case "O":
			return <Card.Header>{lst.codLse}: Liste obligatoire</Card.Header>
		case "X":
			return (
				<Card.Header>
					{lst.codLse}: Liste obligatoire à choix, sélectionner{" "}
					{lst.nbMinElp === lst.nbMaxElp
						? "exactement " +
							lst.nbMinElp +
							(lst.nbMinElp < 2 ? " élément " : " éléments ")
						: "de " + lst.nbMinElp + " à " + lst.nbMaxElp + " éléments "}{" "}
					de premier niveau dans la liste.
				</Card.Header>
			)
	}
	return <Card.Header>{lst.codLse}: Liste d&apos;éléments facultatifs</Card.Header>
}

const titre = (elp : ElementBref_t): string | JSX.Element => {
	const badgeVariant =
		elp.etat.ajour === true ? "success"  : elp.etat.ajour === false ? "warning" : "danger"
	const textVariant = elp.etat.public
		? <>{elp.code + ": " + elp.lic.fr}</>
		: <span className="text-decoration-line-through"> {elp.code + ": " + elp.lic.fr}</span>
	return <>{textVariant}<Badge pill bg={badgeVariant} className="ms-2">x</Badge></>
}

export const ListesAvecTabs = ({ listes, annee, antecedents, refreshParent }: PropsListe): JSX.Element => (
	<Card.Body>
		<Tabs id="listes" className="mx-0" variant="pills" mountOnEnter>
			{listes.map((lst) => (
				<Tab
					key={lst.codLse}
					eventKey={lst.codLse}
					title={lst.codLse + ": " + lst.licLse}
					tabClassName="btn btn-light"
				>
					<Card>
						<ListHeader lst={lst} />
						<Card.Body>
							<Tabs id="elements" className="mx-0" variant="pills" mountOnEnter>
								{lst.elements.map((elp) => (
									<Tab
										key={elp.code}
										eventKey={elp.code}
										title={titre(elp)}
									>
										<Elp
											annee={annee}
											nature={elp.nature}
											code={elp.code}
											antecedents={antecedents} // Propagation des antécédents
											refreshParent={refreshParent}
										/>
									</Tab>
								))}
							</Tabs>
						</Card.Body>
					</Card>
				</Tab>
			))}
		</Tabs>
	</Card.Body>
)

export const ListesSansTabs = ({ listes, annee, antecedents, refreshParent }: PropsListe): JSX.Element => (
	<Card.Body>
		<Tabs id="elements" className="mx-0" variant="pills" mountOnEnter>
			{listes.map((lst) =>
				lst.elements.map((elp) => (
					<Tab
						key={lst.codLse + "-" + elp.code}
						eventKey={lst.codLse + "-" + elp.code}
						title={titre(elp)}
					>
						<Elp
							annee={annee}
							nature={elp.nature}
							code={elp.code}
							antecedents={antecedents} // Propagation des antécédents
							refreshParent={refreshParent}
						/>
					</Tab>
				))
			)}
		</Tabs>
	</Card.Body>
)
