import React from "react"
import { useState } from "react"
import { Card, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { EditButton, HtmlEditorText, OkCancel } from "../utilities/form-utilities"
import { Texte_t } from "./syllabus-types"
import { useSanctum } from "../sanctum/sanctum"
import MarkdownRenderer from "../utilities/markdown"
import TurndownService from "turndown"
import { marked } from "marked"

interface PropsSuivi {
	header?: JSX.Element
	contenu?: string | null
	update?: (value: string) => void
}

export const SuiviRedaction = ({contenu, header, update} : PropsSuivi): JSX.Element | null => {
	const [edit, setEdit] = useState(false)
	const { authState: { user } } = useSanctum()

	// s'il n'y a pas d'utilisateur connecté, on n'affiche rien
	if ( (user ?? null) === null ) return null

	const onclickEdit = () => {
		//console.log({ contenu })
		setEdit(true)
	}

	// -------------------------------
	// 1) Par souci de performance, on peut initialiser TurndownService hors de la fonction onSubmit
	//    si on veut éviter de le recréer à chaque envoi du formulaire
	//    mais on ne l'initialise que s'il y a un utilisateur déclaré.
	// -------------------------------
	const turndownService = new TurndownService({ headingStyle: "atx"})
	// Supprimer les <br> pour ne pas générer de hard breaks
	turndownService.addRule("br", {
		filter: "br",
		replacement: () => ""
	})
	// Supprimer les liens avec un href de placeholder (ex. "#")
	turndownService.addRule("placeholderLinks", {
		filter: (node) => node.nodeName === "A" && node.getAttribute("href") === "#",
		replacement: () => ""
	})
	// Optionnel : supprimer d"autres balises indésirables
	turndownService.remove("style")

	const Formulaire = () => {

		const methodes = useForm<Texte_t>({
			mode: "onChange",
			defaultValues: { texte: marked(contenu ?? "") as string }
		})

		const { control, handleSubmit } = methodes

		const onSubmit = (value: Texte_t) => {
			// 3) À la soumission, on repasse de l'HTML -> Markdown
			const newValue = turndownService.turndown(value.texte)
			setEdit(false)
			//console.log(value)
			if (update) update(newValue)
		}

		const onclickCancel = () => setEdit(false)

		return <Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group controlId="form_commentaire" className="mb-2">
				<Form.Label className='h4 text-primary mt-3 mb-0'>Suivi de rédaction</Form.Label>
				<Form.Text className="text-secondary font-italic">
					Ce texte libre n&apos;est pas destiné à être imprimé ou affiché avec le syllabus. Il
					est simplement un « espace de communication » entre les différents rédacteurs
					pour faciliter le suivi de l&apos;avancement des travaux. Il pourrait, par exemple,
					héberger un petit historique des modifications.
				</Form.Text>
				<HtmlEditorText name="texte" control={control} />
			</Form.Group>
			<OkCancel  cancel={onclickCancel}/>
		</Form>
	}

	return <Card className='text-muted bg-light mx-3 my-3'>
		<Card.Body className='py-2'>
			<Card.Title className="text-primary mb-2">
				<EditButton onClick={onclickEdit}/>
				Suivi de rédaction
			</Card.Title>
			<Card.Subtitle className="text-info font-italic">
				Ce texte libre n&apos;est pas destiné à être imprimé ou affiché avec le syllabus. Il
				est simplement un « espace de communication » entre les différents rédacteurs.
			</Card.Subtitle>
			<MarkdownRenderer content={contenu ?? ""} />
		</Card.Body>
		<Modal dialogClassName="modal-90w" show={edit} keyboard={false} backdrop="static">
			{ header }
			<Modal.Body>{ edit && <Formulaire /> }</Modal.Body>
		</Modal>
	</Card>
}
