import React from "react"
import { TexteBilingueFlat } from "../bilingue-flat"
import { NoticeElpFlat_t } from "../api-syllabus-arbre"
import { useTranslation } from "react-i18next"

interface Props {
	notice?:  NoticeElpFlat_t
}

/**
 * NoticeFlat
 * 
 * Objet React de manipulation (affichage/édition) de la notice descriptive d'un élément
 * 
 * @returns JSX.Element | null 
 */
export const NoticeFlat = ( { notice }: Props): JSX.Element | null => {
	const { t } = useTranslation()

	if (! notice) return null

	// Liste d'éléments pour prérequis mise en attente
	// (demandera sûrement de modifier TexteBilingue...)
	return (
		<>
			{notice.description?.contexte && (
				<TexteBilingueFlat
					html titre={t("noticeFlat.contexteEtObjectifs")}
					texte={notice.description.contexte}
				/>
			)}
			{notice.description?.contenu && (
				<TexteBilingueFlat
					html titre={t("noticeFlat.contenu")}
					texte={notice.description.contenu}
				/>
			)}
			{notice.description?.ressources && (
				<TexteBilingueFlat
					html titre={t("noticeFlat.ressources")}
					texte={notice.description.ressources}
				/>
			)}
			{notice.prerequis?.text && (
				<TexteBilingueFlat
					html titre={t("noticeFlat.prerequis")}
					texte={notice.prerequis.text}
				/>
			)}
			{notice.mcc?.text && (
				<TexteBilingueFlat
					html titre={t("noticeFlat.modalitesControleDesConnaissances")}
					texte={notice.mcc.text}
				/>
			)}
		</>
	)
}
