import React, { useCallback } from "react"
import {
	Reducer,
	useEffect,
	useReducer,
	useState,
	FocusEvent,
	useContext
} from "react"
import Table from "react-bootstrap/Table"
import ListGroup from "react-bootstrap/ListGroup"
import {
	Badge,
	Button,
	Card,
	Col,
	Form,
	InputGroup,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip
} from "react-bootstrap"
import { nanoid } from "nanoid"
import AsyncCreatableSelect from "react-select/async-creatable"
import { RotateCcw } from "react-feather"
import { Placement } from "react-bootstrap/types"
import {
	AcquisAppH_t,
	AcquisAppH_tp,
	AcquisApp_t,
	DescElpRead_t,
	DescElpWrite_t
} from "./syllabus-types"
import { Bilingue_t, LigneBilingue } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { ErrorDetails, ErrorToast } from "../utilities/error-toast"
import { ContexteDetails } from "./details"
import { useSanctum } from "../sanctum/sanctum"

/**
 * AcquisApprentissage
 *
 * Objet React de manipulation (affichage/édition) des acquis d'apprentissage
 * associés à un élément
 *
 * @returns JSX.Element | null
 */
export const AcquisApprentissage = (): JSX.Element | null => {
	const {
		apiAccess,
		authState: { user }
	} = useSanctum()

	const [aaps, setAaps] = useState<AcquisAppH_t[] | null>(null)
	const [erreur, setErreur] = useState<ErrorDetails | null>(null)
	const [edit, setEdit] = useState(false)
	const ctxt = useContext(ContexteDetails)

	const onclickEdit = () => setEdit(true)

	const compareFR = Intl.Collator("fr", { sensitivity: "base" })
	const compareFRstrict = Intl.Collator("fr", { sensitivity: "variant" })
	// const compareEN = Intl.Collator("en", {sensitivity: "base"})
	const compareENstrict = Intl.Collator("en", { sensitivity: "variant" })

	/**
	 * fetchDescription
	 *
	 * fonction asynchrone d'écriture/lecture d'une liste d'acquis d'apprentissage
	 *
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 *
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = useCallback(
		async (valeur: DescElpWrite_t): Promise<void> => {
			try {
				const reponse = await apiAccess.post<DescElpRead_t>(
					ctxt.url,
					valeur
				)
				const aa = reponse.data.acquisapp
				if (aa !== undefined) setAaps(aa)
			} catch (err: unknown) {
				if (err instanceof Error) {
					setErreur({ message: err.message, payload: valeur } as ErrorDetails)
				}
			}
		},
		[apiAccess, ctxt.url]
	)

	useEffect(() => {
		fetchDescription({ acquisapp: null })
	}, [fetchDescription])

	// useEffect(() => console.log({aaps}))

	const aLePar = (aa: AcquisAppH_t) => {
		const date = new Date(aa.updated_at.pivot + "Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa: AcquisAppH_tp) => {
		// à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj + "Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const TemoinModif = (): JSX.Element => (
		<Badge
			bg="danger"
			pill
			className="position-absolute top-0 start-100 translate-middle border border-light"
		>
			&nbsp;
		</Badge>
	)

	const Formulaire = () => {
		type AA_t = {
			// représentation des aa dans le formulaire
			id: string
			del: boolean
			neuf: boolean
			propre: boolean
			org: AcquisAppH_tp | null
			lib: Required<Bilingue_t>
			mcc: Required<Bilingue_t>
			val: string
		}

		type PartialAAt = {
			[P in keyof AA_t]+?: AA_t[P] extends Bilingue_t
				? Partial<Bilingue_t>
				: AA_t[P]
		}

		type action_t = {
			// actions du reducer
			type: string
			value?: PartialAAt
		}

		const aaReducer = (state: AA_t[], action: action_t): AA_t[] => {
			// console.log( { action })
			const ident = action.value?.id
			function aap2aaf(aa: AcquisAppH_tp): AA_t {
				return {
					id: nanoid(),
					del: false,
					neuf: false,
					propre: aa.codElp.includes(aa.troncCodElp),
					org: aa,
					lib: {
						fr: aa.libAcquisApp.fr,
						en: aa.libAcquisApp.en ?? ""
					},
					mcc: { fr: aa.mcc?.fr ?? "", en: aa.mcc?.en ?? "" },
					val: aa.validite ?? "période inconnue"
				}
			}
			switch (action.type) {
				case "reset":
					return []
				case "init": // réinitialisation avec les acquis d'app.
					// Les éléments sont classés avec les 'acquis seulement propagés' en premier
					return (
						aaps
							?.map<AA_t>(aap2aaf)
							.sort((a: AA_t, b: AA_t) =>
								a.org?.validite
									? b.org?.validite
										? compareFR.compare(a.lib.fr, b.lib.fr)
										: 1
									: b.org?.validite
										? -1
										: compareFR.compare(a.lib.fr, b.lib.fr)
							) ?? []
					)
				case "add": {
					// si org est set, il a la priorité
					const libfr =
						action.value?.org?.libAcquisApp ?? action.value?.lib?.fr
					if (libfr === undefined) return state // sortie rapide: on ne peut pas ajouter rien
					if (!state.find((aaf) => aaf.lib.fr === libfr)) {
						// n'a pas été trouvé. On crée en fin de liste.
						const org = action.value?.org
						// prettier-ignore
						const nouveau: AA_t =
							org == null
								? {
									id: nanoid(),
									del: false,
									neuf: true,
									propre: true,
									org: null,
									lib: {
										fr: action.value?.lib?.fr ?? "",
										en: action.value?.lib?.en ?? ""
									},
									mcc: {
										fr: action.value?.mcc?.fr ?? "",
										en: action.value?.mcc?.en ?? ""
									},
									val: "l'année courante et les suivantes (a priori)"
								} : { ...aap2aaf(org), neuf: true }
						return [...state, nouveau]
					}
					// si l'objet a été trouvé, le add est interprété comme un undel
					return state.map((aaf) => {
						if (aaf.lib.fr === libfr)
							return { ...aaf, propre: true, del: false }
						else return aaf
					})
				}
				case "bascule": {
					if (!ident)
						return state // id non indiqué...
					else
						return state.map((aaf) => {
							if (aaf.id === ident) {
								const del =
									aaf.org?.brancheCodElp == null && !aaf.del
								const propre =
									aaf.org?.brancheCodElp == null ||
									!aaf.propre
								return { ...aaf, propre: propre, del: del }
							} else return aaf
						})
				}
				case "maj": {
					// l'aaf est désigné par son id
					if (!ident) return state // id non indiqué...
					return state.map((aaf) => {
						if (aaf.id !== ident) return aaf
						else {
							return {
								// Les champs non mis à jour sont nuls ou undefined (dans action.value)
								id: aaf.id,
								del: aaf.del,
								neuf: aaf.neuf,
								propre: action.value?.propre ?? aaf.propre,
								org: aaf.org,
								lib: {
									fr: action.value?.lib?.fr ?? aaf.lib.fr,
									en: action.value?.lib?.en ?? aaf.lib.en
								},
								mcc: {
									fr: action.value?.mcc?.fr ?? aaf.mcc.fr,
									en: action.value?.mcc?.en ?? aaf.mcc.en
								},
								val: action.value?.val ?? aaf.val
							}
						}
					})
				}
				default:
					return state
			}
		}

		const [aapf, setAapf] = useReducer<Reducer<AA_t[], action_t>>(
			aaReducer,
			[]
		)
		const actionReset: action_t = { type: "reset" }

		// useEffect(() => console.log({aapf}))

		if (aapf.length === 0 && aaps !== null && aaps.length !== 0)
			setAapf({ type: "init" })

		const onclickCancel = () => {
			setEdit(false)
			setAapf(actionReset)
		}
		const onclickValid = () => {
			// prettier-ignore
			const aa: Partial<AcquisApp_t>[] = aapf
				.filter((aaf) => !aaf.del && aaf.propre)
				.map<Partial<AcquisApp_t>>((aaf) =>
					aaf.neuf ||
					aaf.org === null ||
					aaf.lib.fr !== aaf.org.libAcquisApp.fr ||
					aaf.lib.en !== (aaf.org.libAcquisApp.en ?? "") ||
					aaf.mcc.fr !== (aaf.org.mcc?.fr ?? "") ||
					aaf.mcc.en !== (aaf.org.mcc?.en ?? "")
						? {
							// tout est transféré tel qu'affiché, sauf la validité qui sera recalculée en backend
							// idAcquisApp: aaf.org?.idAcquisApp ?? -1,
							libAcquisApp: {
								fr: aaf.lib.fr,
								en: aaf.lib.en
							},
							mcc: aaf.mcc
						} : {
							libAcquisApp: {
								fr: aaf.lib.fr
							}
						}
				)
			fetchDescription({ acquisapp: aa })
			setEdit(false)
			setAapf(actionReset)
		}

		// prettier-ignore
		const suggestionsAAP = async (inputValue: string) => {
			return inputValue.length > 2
				? apiAccess.post<
						{
							fr: string
							en?: string
							updated_at: Date
							updated_by?: string
						}[]
					>("api/selecteur/acquisapp", { motif: inputValue })
					.then((res) =>
						res.data.map((x) => {
							return {
								value: x,
								label: x.fr
							}
						})
					)
				: Promise.resolve([])
		}

		const Prologue = () => (
			<div className="lh-sm">
				<p>
					Les acquis d&apos;apprentissage visés regroupent les
					connaissances et les compétences développées dans
					l&apos;élément de programme.
				</p>
				<p>
					Le champ facultatif &quot;Preuve d&apos;apprentissage
					attendue&quot; sert à préciser quel type de preuve permet de
					valider l&apos;acquis d&apos;apprentissage considéré.
				</p>
				<details className="col-12">
					<summary>Définition d&apos;une connaissance</summary>
					<p>
						Ce que l’on appelle, en sciences cognitives, les
						connaissances déclaratives. On parle aussi de savoirs :
						il s’agit de la connaissance de faits, de règles, de
						lois, de principes, de théorèmes, etc.
					</p>
				</details>
				<details className="col-12">
					<summary>Définition d&apos;une compétence</summary>
					<p>
						Une compétence se définit à travers quatre
						caractéristiques essentielles.
					</p>
					<ol>
						<li>
							<b>Mobilisation d’un ensemble de ressources.</b>
							Tout d’abord la compétence fait appel à la
							mobilisation d’un ensemble de ressources : des
							connaissances, des savoirs d’expérience, des
							schèmes, des automatismes, des capacités, des
							savoir-faire de différents types, etc.
						</li>
						<li>
							<b>Caractère finalisé.</b> Cette mobilisation ne se
							fait pas gratuitement, fortuitement, scolairement
							même. La compétence est finalisée : elle a une
							fonction sociale, une utilité sociale, l’expression
							&quot;fonction sociale&quot; étant prise dans le
							sens large du terme, dans le sens de &quot;porteur
							de sens&quot; pour l’élève. Les ressources diverses
							sont mobilisées par l’élève en vue d’une production,
							d’une action, de la résolution d’un problème qui se
							pose dans sa pratique scolaire ou dans sa vie
							quotidienne, mais qui, en tout état de cause,
							présente un caractère significatif pour lui.
						</li>
						<li>
							<b>Lien à une famille de situations.</b> Cette
							mobilisation se fait à propos d’une famille bien
							déterminée de situations. La compétence de prendre
							des notes lors d’un cours de dernière année de
							l’enseignement secondaire n’est pas la même que la
							compétence de prendre des notes lors d’une réunion.
							Ces deux prises de notes répondent à des exigences
							différentes, parce que les paramètres de la
							situation sont différents (densité des informations,
							diversité des sources, niveau de stress des
							locuteurs, mais surtout une fonction différente...).
							De même, on peut être compétent pour résoudre un
							problème mathématique et ne pas l’être pour résoudre
							un problème en physique. Pour développer une
							compétence, on va restreindre les situations dans
							lesquelles l’élève sera appelé à exercer la
							compétence. L’élève est soumis certes à une variété
							de situations, et cette variété est nécessaire, mais
							il s’agit d’une variété limitée, qui se situe à
							l’intérieur d’une famille donnée de situations. Il
							est important de choisir une famille de situations
							qui soit clairement identifiable à travers quelques
							paramètres. S’il n’y avait qu’une situation dans
							laquelle on exerçait sa compétence, le fait
							d’exercer sa compétence serait de la reproduction
							pure et simple. À l’opposé, définir une compétence à
							travers un spectre trop large de situations ne
							permettrait pas de prononcer la compétence de
							quelqu’un à un moment donné.
						</li>
						<li>
							<b>Evaluabilité.</b> Une compétence doit être
							évaluable. Elle doit pouvoir se mesurer en évaluant
							la qualité de l’exécution d’une tâche et la qualité
							du résultat.
						</li>
					</ol>
				</details>
				<p className="mt-2">
					L&apos;affichage reprend les acquis d&apos;apprentissage
					déclarés sur les éléments constitutifs de l&apos;élément
					courant (on parle d&apos;acquis d&apos;apprentissage
					&ldquo;propagés&rdquo;). Seuls les acquis
					d&apos;apprentissage propres à l&apos;élément courant sont
					modifiables. Le bouton de couleur placé sous l&apos;acquis
					d&apos;apprentissage permet de supprimer ou réactiver un
					acquis d&apos;apprentissage déclaré.
				</p>
				<p>
					Si ce bouton est vert, il indique que l&apos;acquis
					d&apos;apprentissage est affecté en propre à l&apos;élement
					considéré. S&apos;il est jaune, l&apos;acquis
					d&apos;apprentissage est seulement hérité. Enfin, s&apos;il
					est gris, l&apos;acquis d&apos;apprentissage ne sera pas, ou
					plus, affecté à cet élément. Enfin, une pastille rouge sur
					ce bouton indique qu&apos;il y a eu un changement pour
					l&apos;acquis d&apos;apprentissage considéré par rapport à
					ce qui est enregistré dans le Syllabus.
				</p>
				<p>
					Le bouton <RotateCcw size={16} /> placé à droite d&apos;un
					champ permet d&apos;annuler les modifications faites à ce
					champ. A l&apos;ouverture du formulaire, on affiche
					d&apos;abord les acquis d&apos;apprentissage seulement
					propagés, puis les acquis d&apos;apprentissage propres dans
					l&apos;ordre alphabétique de leurs libellés. Les acquis
					d&apos;apprentissage ajoutés sont placés en fin de liste.
					Ils seront interclassés lorsque le formulaire aura été
					validé. Enfin, les modifications ne deviennent définitives
					qu&apos;après validation.
				</p>
				<p>
					Dernière remarque: une fois le formulaire validé, les acquis
					d&apos;apprentissage sont stockés dans un dictionnaire et il
					n&apos;est plus possible de modifier leur libellé. Pour
					corriger l&apos;orthographe du libellé français d&apos;un
					acquis d&apos;apprentissage, il faut en créer un nouveau et
					effacer (détacher) l&apos;ancien. De temps à autre, un
					ramasse miettes enlèvera du dictionnaire les acquis
					d&apos;apprentissage inutilisés. Ceci ne concerne pas la
					preuve d&apos;apprentissage ou les libellés anglais qui sont
					librement modifiables.
				</p>
			</div>
		)

		const deco = (aaf: AA_t) =>
			aaf.del
				? { textDecoration: "line-through red wavy" }
				: aaf.propre
					? { textDecoration: "none" }
					: { textDecoration: "line-through yellow" }

		interface PropsFormAA {
			aaf: AA_t
			readonly?: boolean
		}

		const LibAcquisApp = ({ aaf }: PropsFormAA) => (
			<Col>
				<OverlayTrigger
					placement={"top"}
					overlay={
						<Tooltip id={`tooltip-${aaf.id}`}>
							Défini pour {aaf.val}
						</Tooltip>
					}
				>
					<Form.Control
						value={aaf.lib.fr}
						as="textarea"
						rows={Math.ceil((1 + aaf.lib.fr.length) / 55)}
						readOnly
						style={
							aaf.del
								? { textDecoration: "line-through red wavy" }
								: { textDecoration: "none" }
						}
					/>
				</OverlayTrigger>
			</Col>
		)

		interface PropsInputFormAA extends PropsFormAA {
			placeOvl: Placement
			nom: string
			valeur: string
			changeFn: (a: string) => PartialAAt
			reset: PartialAAt
		}

		const InputAcquisApp = ({
			aaf,
			placeOvl,
			nom,
			changeFn,
			valeur,
			reset,
			readonly = false
		}: PropsInputFormAA) => {
			const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
				if (!readonly)
					setAapf({
						type: "maj",
						value: { ...changeFn(event.target.value), id: aaf.id }
					})
			}
			const resetVal = () => {
				if (!readonly)
					setAapf({ type: "maj", value: { ...reset, id: aaf.id } })
			}
			return (
				<Col>
					<OverlayTrigger
						placement={placeOvl}
						overlay={
							<Tooltip id={`${nom}-${aaf.id}`}>{nom}</Tooltip>
						}
					>
						<InputGroup>
							<Form.Control
								as="textarea"
								defaultValue={valeur}
								onBlur={onBlur}
								placeholder={nom}
								readOnly={readonly}
								rows={Math.ceil((1 + valeur.length) / 55)}
								style={deco(aaf)}
							/>
							{/* <InputGroup.Append style={ {display: "flex", flexDirection: "column", justifyContent: "center" } }> */}
							<RotateCcw
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center"
								}}
								size={16}
								className="btnSyllabus ml-2"
								onClick={resetVal}
							/>
							{/* </InputGroup.Append> */}
						</InputGroup>
					</OverlayTrigger>
				</Col>
			)
		}

		interface MarqueSiModifProps {
			obj: AA_t
		}

		const MarqueSiModif = ({
			obj
		}: MarqueSiModifProps): JSX.Element | null => {
			if (obj.neuf) {
				if (obj.del) return null
			} else if (
				!obj.del &&
				((obj.org?.validite ?? null) !== null) === obj.propre &&
				compareENstrict.compare(
					obj.org?.libAcquisApp.en ?? "",
					obj.lib.en
				) === 0 &&
				compareFRstrict.compare(obj.org?.mcc?.fr ?? "", obj.mcc.fr) ===
					0 &&
				compareENstrict.compare(obj.org?.mcc?.en ?? "", obj.mcc.en) ===
					0
			)
				return null
			return <TemoinModif />
		}

		// prettier-ignore
		return ( <>
			<h1 className="mt-2">Acquis d&apos;apprentissage visés</h1>
			<Prologue />
			<OkCancel cancel={onclickCancel} valid={onclickValid} />
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Form>
				{aapf && aapf.map((aaf) => (
					<Row
						key={`aaf${aaf.id}`}
						className="my-2 p-1 border border-primary rounded"
					>
						<Col>
							<Row>
								<LibAcquisApp aaf={aaf} />
								<InputAcquisApp
									aaf={aaf}
									nom="Learning outcome"
									placeOvl="top"
									changeFn={(v: string) => ({
										lib: { en: v }
									})}
									valeur={aaf.lib.en ?? ""}
									reset={{
										lib: { en: aaf.org?.libAcquisApp.en ?? "" }
									}}
									readonly={aaf.del || !aaf.propre}
								/>
							</Row>
							{aaf.org?.updated_by?.obj && (
								<p className="text-info small my-0">
									Édité {aLeParObj(aaf.org)}
								</p>
							)}
							<Row>
								<InputAcquisApp
									aaf={aaf}
									nom="Preuve d'apprentissage attendue"
									placeOvl="bottom"
									changeFn={(v: string) => ({
										mcc: { fr: v }
									})}
									valeur={aaf.mcc.fr ?? ""}
									reset={{
										mcc: { fr: aaf.org?.mcc?.fr ?? "" }
									}}
									readonly={aaf.del || !aaf.propre}
								/>
								<InputAcquisApp
									aaf={aaf}
									nom="Expected evidence of learning"
									placeOvl="bottom"
									changeFn={(v: string) => ({
										mcc: { en: v }
									})}
									valeur={aaf.mcc.en ?? ""}
									reset={{
										mcc: { en: aaf.org?.mcc?.en ?? "" }
									}}
									readonly={aaf.del || !aaf.propre}
								/>
							</Row>
							<Row className="mx-0 mt-1">
								<Col>
									<Button
										className="px-2 py-0 position-relative"
										type="button"
										variant={
											aaf.propre && !aaf.del
												? "success"
												: aaf.org?.brancheCodElp
													? "warning"
													: "secondary"
										}
										onClick={() =>
											setAapf({
												type: "bascule",
												value: { id: aaf.id }
											})
										}
									>
										{aaf.org?.brancheCodElp
											? ( aaf.propre
												? `Propre à l'élément ${ctxt.code} et propagé de `
												: "Seulement propagé de "
											) +	aaf.org.brancheCodElp.join( ", " )
											: aaf.del
												? `Propre à l'élément ${ctxt.code} mais effacé`
												: `Propre à l'élément ${ctxt.code}`}
										<MarqueSiModif obj={aaf} />
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</Form>
			<AsyncCreatableSelect
				cacheOptions
				loadOptions={suggestionsAAP}
				onCreateOption={(inputValue: string) =>
					setAapf({
						type: "add",
						value: { lib: { fr: inputValue } }
					})
				}
				onChange={(aap) => {
					if (aap && "value" in aap)
						setAapf({
							type: "add",
							value: {
								org: {
									//idAcquisApp: undefined,
									libAcquisApp: {
										fr: aap.value.fr,
										en: aap.value.en
									},
									troncCodElp: ctxt.code,
									codElp: [ctxt.code],
									caractere: "O",
									brancheCodElp: null,
									mcc: null,
									updated_at: {
										obj: aap.value.updated_at,
										pivot: aap.value.updated_at
									},
									updated_by: {
										obj: aap.value.updated_by ?? null,
										pivot: null
									}
								}
							}
						})
				}}
				className="mb-2 mt-2"
			/>
			<OkCancel cancel={onclickCancel} valid={onclickValid} />
		</>	)
	}

	const widthCol = (val: number) => ({ width: val + "%" })

	const lstRows = aaps?.map((aa, index) => {
		const famille = aa.brancheCodElp ?? aa.codElp
		return (
			<tr key={aa.idAcquisApp}>
				<th scope="row" className="text-center py-2">
					{index + 1}
				</th>
				<td className="list-group p-1">
					<ListGroup className="p-1">
						<ListGroup.Item className="p-1">
							<LigneBilingue texte={aa.libAcquisApp} />
							{aa.updated_by.obj && (
								<p className="text-info small my-0">
									Édité {aLeParObj(aa)}
								</p>
							)}
						</ListGroup.Item>
						{aa.mcc && (
							<ListGroup.Item className="p-1">
								<span className="text-muted">
									Preuve d&apos;apprentissage attendue
								</span>
								<br />
								<LigneBilingue texte={aa.mcc} />
							</ListGroup.Item>
						)}
					</ListGroup>
					<p className="text-info small my-0 mx-2">
						{aa.validite !== null
							? aa.brancheCodElp === null
								? aa.updated_by.pivot
									? "Affecté " + aLePar(aa)
									: ""
								: "Propre à l'élément " +
									aa.troncCodElp +
									(aa.updated_by.pivot
										? ` (affecté ${aLePar(aa)})`
										: "") +
									" et propagé de "
							: aa.brancheCodElp === null
								? ""
								: "Propagé de "}
						{aa.brancheCodElp && famille.join(", ")}
					</p>
				</td>
			</tr>
		)
	})

	if ((lstRows?.length ?? 0) === 0 && (user ?? null) === null) return null

	return (
		<Row>
			<Col xs="12">
				<ErrorToast erreur={erreur} onDismiss={setErreur} />
				<Card className="mx-1 mb-1">
					<Card.Header className="text-primary h5">
						<EditButton onClick={onclickEdit} />
						Acquis d&apos;apprentissage
					</Card.Header>
					<Card.Body className="py-2">
						<Table hover size="sm" className="my-0">
							<colgroup>
								<col style={widthCol(5)} />
								<col style={widthCol(95)} />
							</colgroup>
							<thead className="thead-light">
								<tr>
									<th scope="col" className="text-center">
										#
									</th>
									<th scope="col" className="text-left">
										Libellé
									</th>
								</tr>
							</thead>
							<tbody>{lstRows}</tbody>
						</Table>
					</Card.Body>
					<Modal
						dialogClassName="modal-90w"
						show={edit}
						keyboard={false}
						backdrop="static"
					>
						{ctxt.header}
						<Modal.Body>{edit && <Formulaire />}</Modal.Body>
					</Modal>
				</Card>
			</Col>
		</Row>
	)
}
