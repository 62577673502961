import React from "react"
import { Card, Col } from "react-bootstrap"
import { CplDuree_t } from "../../syllabus/syllabus-types"
import { useTranslation } from "react-i18next"

interface PropsTravail {
	cplDuree?: CplDuree_t | null
}

export const TravailFlat = ({ cplDuree }: PropsTravail): JSX.Element | null => {
	const { t } = useTranslation()

	const renderCplDuree = (): JSX.Element | null => {
		if (!cplDuree) return null
		switch (cplDuree.type) {
			case "stage": return (
				<Card.Text className="mb-0">
					{t("travailFlat.stage", { min: cplDuree.min, max: cplDuree.max })}
				</Card.Text>
			)
			case "projet": { // Rem: count est requis pour la pluralisation
				const etDescription = cplDuree.dureeET > 0 
					? t("travailFlat.projet.etDescription", { count: cplDuree.dureeET })
					: t("travailFlat.projet.etDescriptionNoET")
				const encadrement = cplDuree.fafp > 0 
					? t("travailFlat.projet.encadrement", { count: cplDuree.fafp })
					: ""
				const heuresSup = cplDuree.dureeHET > 0 
					? t("travailFlat.projet.heuresSup", { count: cplDuree.dureeHET })
					: t("travailFlat.projet.heuresSupNoHET")
				return (
					<Card.Text className="mb-0">
						{t("travailFlat.projet.full", {
							etDescription,
							encadrement,
							heuresSup
						})}
					</Card.Text>
				)
			}
			case "ens": { // Rem: count est requis pour la pluralisation
				const ensSuffix = cplDuree.the > 0
					? t("travailFlat.ens.withThe", { count: cplDuree.the })
					: t("travailFlat.ens.withoutThe")
				return (
					<Card.Text className="mb-0">
						{t("travailFlat.ens.full", { ensSuffix })}
					</Card.Text>
				)
			}
			case "val": { // Rem: count est requis pour la pluralisation
				const valFafp = cplDuree.fafp > 0
					? t("travailFlat.val.fafp", { count: cplDuree.fafp })
					: t("travailFlat.val.noFafp")
				const valThe = cplDuree.the > 0
					? t("travailFlat.val.the", { count: cplDuree.the })
					: t("travailFlat.val.noThe")
				return (
					<Card.Text className="mb-0">
						{t("travailFlat.val.full", { valFafp, valThe })}
					</Card.Text>
				)
			}
			default:
				return null
		}
	}
	
	return (
		<Col xs={12} className="mx-0 mt-2 mb-1">
			<h3>{t("travailFlat.header")}</h3>
			{renderCplDuree()}
		</Col>
	)
}
