import React from "react"
import { Button, Form, FormControl, Row, Col } from "react-bootstrap"
import { Controller, Control, useFormContext } from "react-hook-form"
import { Editor } from "@tinymce/tinymce-react"
import { Edit } from "react-feather"
import { Texte_t } from "../syllabus/syllabus-types"
import { Bilingue_t } from "./bilingue"
import { useSanctum } from "../sanctum/sanctum"

const mceInit = {
	plugins: `autosave code fullscreen help link lists 
		searchreplace visualblocks visualchars wordcount`,
	menubar: false,
	toolbar1: "undo redo bold italic alignleft aligncenter alignright alignjustify alignnone "
				+ "removeformat outdent indent numlist bullist hr visualchars visualblocks code wordcount fullscreen",
	toolbar2: "searchreplace selectall copy cut paste pastetext h1 h2 h3 h4 h5 h6 "
				+ "link openlink unlink print help",
}


interface PropsHtmlEditorBilingue {
	name: "fr" | "en"
	control: Control<Bilingue_t>
}

interface PropsHtmlEditorSimple {
	name: "texte"
	control: Control<Texte_t>
}

export const HtmlEditorBilingue = ({
	name,
	control
}: PropsHtmlEditorBilingue): JSX.Element => (
	<Controller
		name={name}
		control={control}
		render={({ field: { onChange, value, name } }) => (
			<Editor
				value={value}
				textareaName={name}
				onEditorChange={onChange}
				init={mceInit}
				licenseKey="gpl"
				tinymceScriptSrc='/tinymce/tinymce.min.js'
			/>
		)}
	/>
)

export const HtmlEditorText = ({
	name,
	control
}: PropsHtmlEditorSimple): JSX.Element => (
	<Controller
		name={name}
		control={control}
		render={({ field: { onChange, value, name } }) => (
			<Editor
				value={value}
				textareaName={name}
				onEditorChange={onChange}
				init={mceInit}
				licenseKey="gpl"
				tinymceScriptSrc='/tinymce/tinymce.min.js'
			/>
		)}
	/>
)

interface PropsOkCancel {
	cancel: () => void
	valid?: () => void
}

export const OkCancel = ({ cancel, valid }: PropsOkCancel): JSX.Element => (
	<>
		<Button
			variant="success"
			onClick={valid}
			type={valid ? "button" : "submit"}
			size="sm"
		>
			Valider
		</Button>{" "}
		<Button variant="danger" size="sm" onClick={cancel}>
			Annuler
		</Button>
	</>
)

interface PropsEditButton {
	onClick: () => void
}

export const EditButton = ({
	onClick
}: PropsEditButton): JSX.Element | null => {
	const {
		authState: { user }
	} = useSanctum()
	return user ? (
		<Button
			name="edit"
			variant="outline-primary"
			size="sm"
			className="col-auto me-2"
			onClick={onClick}
		>
			<Edit color="purple" size={18} />
		</Button>
	) : null
}

interface PropsFormLibelle {
	nom: string
	labSz?: number
	label: string
	number?: boolean
	contrainte?: {
		[ix: string]:
			| boolean
			| number
			| string
			| (() => boolean)
			| { [ixx: string]: () => boolean }
	}
	invalide?: boolean
	feedback?: string
	children: React.ReactNode
}

export const FormLibelle = ({
	nom,
	labSz,
	label,
	number,
	contrainte,
	invalide,
	feedback,
	children
}: PropsFormLibelle): JSX.Element => {
	const { register } = useFormContext()
	labSz = labSz ?? 4
	return (
		<Form.Group as={Row} controlId={`form_${nom}`}>
			<Form.Label column sm={labSz} className="text-primary">
				{label}
			</Form.Label>
			<Col sm={labSz !== 12 ? 12 - labSz : 12}>
				<Form.Control
					as="input"
					type={number ? "number" : "text"}
					{...register(nom, contrainte)}
					isInvalid={!!invalide}
				/>
				{feedback && contrainte && (
					<FormControl.Feedback type="invalid">
						{feedback}
					</FormControl.Feedback>
				)}
			</Col>
			{children && (
				<Col className="lh-sm mb-2">
					<Form.Text className="text-secondary">{children}</Form.Text>
				</Col>
			)}
		</Form.Group>
	)
}
