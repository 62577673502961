import React from "react"
import { useContext } from "react"
import { Col } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat } from "../imprimable"
import { useTranslation } from "react-i18next"

export interface AnglaisFlat_t {
	modalite: boolean
	factuel: boolean
}

interface PropsLangue {
	modAnglais: boolean
	factAnglais: boolean
}

export const LangueFlat = ({modAnglais, factAnglais} : PropsLangue): JSX.Element => {
	const { t } = useTranslation()
	const ctxt = useContext(ContexteFormationFlat)

	// si terminal, cplDuree, anglais et modalites réclament un formulaire particulier.
	// mis en attente

	const libAnglais = (() => {
		if (modAnglais)
			return {
				en: t("langueFlat.taughtEnglish", { lng: "en" }),
				fr: t("langueFlat.taughtEnglish", { lng: "fr" })
			}
		if (factAnglais) return {
			en: t("langueFlat.taughtFrenchCanBeEnglish", { lng: "en" }),
			fr: t("langueFlat.taughtFrenchCanBeEnglish", { lng: "fr" })
		}
		else return {
			en: t("langueFlat.taughtFrench", { lng: "en" }),
			fr: t("langueFlat.taughtFrench", { lng: "fr" })
		}
	}) ()

	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>{t("langueFlat.title")}</h3>
		<LigneBilingueFlat texte={ libAnglais } langue={ctxt.langue}/>
	</Col>

}
