import React from "react"
import { Card } from "react-bootstrap"
import { ElpFlat } from "./elp-flat"
import { ListeFlat_t } from "./api-syllabus-arbre"
import { useTranslation } from "react-i18next"

interface PropsListe {
	annee: number
	listes: Array<ListeFlat_t>
}

interface PropsHeader {
	lst: ListeFlat_t
}

// prettier-ignore
const ListHeader = ({ lst }: PropsHeader) => {
	const { t } = useTranslation()
	switch (lst.typLse) {
		case "O" : return (
			<Card.Header>
				{t("listesFlat.mandatoryList", { code: lst.codLse })}
			</Card.Header>
		)
		case "X" : {
			const selectionText =
				lst.nbMinElp === lst.nbMaxElp
					? t("listesFlat.mandatoryChoiceList.exactly", { code: lst.codLse, count: lst.nbMinElp })
					: t("listesFlat.mandatoryChoiceList.range", { code: lst.codLse, min: lst.nbMinElp, max: lst.nbMaxElp })
			return <Card.Header>{selectionText}</Card.Header>
		}
		default: return (
			<Card.Header>
				{t("listesFlat.optionalList", { code: lst.codLse })}
			</Card.Header>
		)
	}
}

export const ListesAvecTabsFlat = ({
	listes,
	annee
}: PropsListe): JSX.Element => (
	<>
		{listes.map((lst) => (
			<Card key={lst.codLse} className="mt-1">
				<ListHeader lst={lst} />
				<Card.Body className="pt-2">
					{lst.elements.map((elp) => (
						<ElpFlat annee={annee} element={elp} key={elp.code} />
					))}
				</Card.Body>
			</Card>
		))}
	</>
)

export const ListesSansTabsFlat = ({
	listes,
	annee
}: PropsListe): JSX.Element => (
	<>
		{listes.map((lst) =>
			lst.elements.map((elp) => (
				<ElpFlat
					annee={annee}
					element={elp}
					key={lst.codLse + "-" + elp.code}
				/>
			))
		)}
	</>
)
